import { Global } from "@emotion/react";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrate } from "react-dom";

import globalStyles from "./global.styles";

export const entryClient = () => {
  startTransition(() => {
    // Replace with hydrateRoot once React fixes hydration issue in 18.3
    hydrate(
      <StrictMode>
        <Global styles={globalStyles} />
        <RemixBrowser />
      </StrictMode>,
      document
    );
  });
};

entryClient();
