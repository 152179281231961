import { css } from "@emotion/react";
import { Spacings } from "@polestar/component-warehouse-react";

const globalStyles = css`
  :lang(ko) {
    word-break: keep-all;
  }
  @media print {
    body * {
      visibility: hidden;
    }
    #printable * {
      visibility: visible;
    }
    #printable {
      position: absolute;
      left: 0;
      top: ${Spacings.xLarge};
    }
  }
`;
export default globalStyles;
